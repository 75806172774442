<template>
  <div>
    <a-form layout="inline" :model="searchFormState" style="margin-bottom: 20px">
      <a-form-item label="姓名">
        <a-input
          v-model:value="searchFormState.fullName"
          placeholder="请输入姓名"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>

      <a-form-item label="账号" class="ml">
        <a-input
          v-model:value="searchFormState.accountName"
          placeholder="请输入账号"
          class="searchInputWidth"
        ></a-input>
      </a-form-item>
      <a-form-item style="margin-left: 20px">
        <a-button type="primary" @click="search(searchFormState)" style="border-radius: 4px">
          <template #icon>
            <search-outlined />
          </template>
          查询
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="clearFormState(searchFormState)">
          <template #icon>
            <redo-outlined />
          </template>
          重置
        </a-button>
      </a-form-item>
    </a-form>

    <div class="mb-20">
      <a-button type="primary" @click="operate('add')">
        <template #icon>
          <plus-outlined />
        </template>
        新增
      </a-button>

      <!-- <a-button @click="batchOperate('delete')" class="ml20">
        <template #icon></template>
        批量删除
      </a-button>-->
    </div>

    <!-- 表格数据展示 -->
    <a-table
      :columns="columns"
      :data-source="tabaleData"
      bordered
      :pagination="false"
      :rowKey="(item) => item.id"
    >
      <template #switch="{ record }">
        <a-popconfirm
          :title="`确认${record.userEnable !== 1 ? '启用' : '停用' }`"
          ok-text="确认"
          cancel-text="取消"
          @confirm="simpleStopUse(record)"
        >
        <a-switch
          :checked="record.userEnable === 1"
          checked-children="可用"
          un-checked-children="停用"
        />
        </a-popconfirm>
        <!-- <a-switch
          v-model:checked="record.switch"
          checked-children="可用"
          un-checked-children="停用"
          @change="simpleStopUse(record)"
        /> -->
      </template>
      <template #operation="{ record }">
        <a style="color: #637cfe" @click="operate('edit', record)">编辑</a>
        <a-divider type="vertical" />
        <a-popconfirm title="确认删除?" @confirm="operate('delete', record)">
          <a href="#">删除</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm title="重置密码为888888?" @confirm="operate('reset', record)">
          <a href="#">重置密码</a>
        </a-popconfirm>
      </template>
    </a-table>

    <div
      style="
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      "
    >
      <a-pagination
        v-model:current="pagination.current"
        :total="pagination.total"
        v-if="tabaleData.length"
        @change="paginationChange"
        v-model:pageSize="pagination.pageSize"
        show-size-changer
        @showSizeChange="onShowSizeChange"
        :page-size-options="pagination.pageSizeOptions"
        :show-total="(total) => `共 ${total} 条`"
        show-quick-jumper
      />
    </div>

    <modal
      :title="(modalState ? '新增' : '编辑') + '人员'"
      :width="780"
      ref="customDialog"
      @handleOk="sumbitEditForm"
    >
      <a-form
        name="custom-validation"
        ref="formRef"
        :model="formState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :rules="formStateRules"
      >
        <a-form-item label="账号" name="accountName">
          <a-input
            v-model:value="formState.accountName"
            autocomplete="off"
            style="width:400px"
            :disabled="!modalState"
            policeholder="请输入账号"
          />
        </a-form-item>
        <a-form-item label="姓名" name="fullName">
          <a-input
            v-model:value="formState.fullName"
            autocomplete="off"
            style="width:400px"
            policeholder="请输入用姓名"
            :maxlength="10"
          />
        </a-form-item>
        <a-form-item label="密码" name="password" v-if="modalState">
          <a-input
            v-model:value="formState.password"
            type="password"
            autocomplete="off"
            style="width:400px"
            policeholder="请输入密码"
          />
        </a-form-item>
      </a-form>
    </modal>
  </div>
</template>

<script>
import {
  SearchOutlined,
  RedoOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { myPost, myGet } from "@/common/request.js"
import { defineComponent, reactive, ref, onMounted } from "vue";
import Modal from "@/components/modal.vue";
import { encode64 } from "@/common/security.js";
import { message } from "ant-design-vue"

const api = {
  // 查询
  queryPageUser: '/xkcwebApi/user/queryPageUser.htm',
  //更新
  updateUserInfo: '/xkcwebApi/user/updateUserInfo.htm',
  // 新增用户
  xkcWebCreate: '/xkcwebApi/user/xkcWebCreate.htm',
  // 删除用户
  deleteUserUrl: '/xkcwebApi/user/deleteUserById.htm',
  // 单个启用或者停用
  simpleUseUrl: '/xkcwebApi/user/updateUserEnable.htm',
  // // 重置密码
  resetUserUrl: '/xkcwebApi/user/resetUserPwd.htm',
  // // 编辑用户
  editUserUrl: '/xkcwebApi/user/updateUserInfo.htm',
  // // 批量删除
  // batchDeleteUrl: '/api/admin/system/user/batchDeleteUserById.htm'
};

export default defineComponent({
  components: {
    SearchOutlined,
    RedoOutlined,
    PlusOutlined,
    Modal,
  },
  setup() {
    const customDialog = ref(null);

    const searchFormState = reactive({
      fullName: null,
      accountName: null,
    });

    // 搜索查询
    const search = () => {
      queryList(1, searchFormState)
    };

    // const layout 

    let validator_re_pwd = async (rule, value) => {
      if (value !== formState.password) {
        return Promise.reject("两次输入不一致!");
      } else {
        return Promise.resolve();
      }
    };
    const formStateRules = {
      accountName: [
        {
          required: true,
          message: "姓名不能为空",
          trigger: ["blur", "change"],
          whitespace: true,
        },
        {
          pattern: "^[a-zA-Z][a-zA-Z0-9_]*$",
          message: "账号字母开头，由字母，数字，下划线组成",
        },
      ],
      password: [
        {
          required: true,
          message: "密码不能为空",
          trigger: ["blur", "change"],
          whitespace: true,
        },
      ],
      confirmPass: [
        {
          required: true,
          message: "确认密码不能为空",
          trigger: ["blur", "change"],
          whitespace: true,
        },
        {
          validator: validator_re_pwd,
        },
      ],
      fullName: [
        {
          required: true,
          message: "账号不能为空",
          trigger: ["blur", "change"],
        },
      ],
    };
    const sumbitEditForm = () => {
      formRef.value.validate().then(() => {
        if (modalState.value) {
          let tempParam = {
            ...formState
          }
          // 加密处理
          tempParam.accountName = encode64(tempParam.accountName)
          tempParam.password = encode64(tempParam.password)
          myPost(api.xkcWebCreate, tempParam).then((res) => {
            if (res.code === 200) {
              customDialog.value.closeModal()
              message.success('新增成功')
              queryList('', searchFormState)
            }
          })
            .catch((err) => {
              console.log(err);
            })
        } else {
          let tempParam = {
            ...formState
          }
          // delete tempParam.confirmPass
          // tempParam.roleId = Number(tempParam.roleId)
          // 加密处理
          tempParam.accountName = encode64(tempParam.accountName)
          // tempParam.password = encode64(tempParam.password)
          myPost(api.editUserUrl, tempParam).then((res) => {
            customDialog.value.closeModal()
            message.success(res.message)
            queryList('', searchFormState)
          })
            .catch((err) => {
              console.log(err);
            })
        }
      })
        .catch((err) => {
          console.log(err);
        })
    };

    // 清除表单
      const clearFormState = (form, type) => {
        for (var i in form) {
          form[i] = null
        }
        type !='handle' && queryList(1)
      }

    // const rolesOptions = ref([]);

    const modalState = ref(true);

    let formState = reactive({
      accountName: "",
      password: "",
      fullName: "",
    });

    const columns = [
      {
        title: "姓名",
        dataIndex: "fullName",
        key: "fullName",
        align: "center",
      },
      {
        title: "账号",
        dataIndex: "accountName",
        key: "accountName",
        align: "center",
      },
      {
        title: "注册日期",
        dataIndex: "createTime",
        align: "center",
        slots: {
          customRender: true,
        },
      },
      {
        title: '最后登陆时间',
        dataIndex: 'lastLoginTime',
        align: 'center',
        slots: {
          customRender: true,
        }
      },
      {
        title: '当前状态',
        dataIndex: 'switch',
        align: 'center',
        slots: {
          customRender: 'switch',
        }
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: {
          customRender: "operation",
        },
        align: "center",
      },
    ];
    // 表格数据
    const tabaleData = ref([]);
    tabaleData.value = [
      {
        id: 1,
        courseHeading: "123",
        coursePicture: "123",
        writer: "123",
        recommend: "123",
        courseState: "123",
        uploadOwner: "123",
        maintenanceTime: "123",
        operationStatus: ''
      },
    ];

    // delete

    const formRef = ref("")
    const operate = (type, record) => {
      modalState.value = type == 'add' ? true : false
      if (type == "edit") {
        formRef.value && formRef.value.resetFields()
        clearFormState(formState, 'handle')
        formState.id = record.userId;
        formState.accountName = record.accountName;
        formState.fullName = record.fullName;
        // debugger
        customDialog.value.showModal();
      } else if (type == "delete") {
        myGet(api.deleteUserUrl, { id: record.userId }).then(res => {
          message.success(res.message)
          queryList('', searchFormState)
        })
      } else if (type == "add") {
        formRef.value && formRef.value.resetFields()
        clearFormState(formState, 'handle')
        customDialog.value.showModal();
      } else if (type == "reset") {
        myGet(api.resetUserUrl, { id: record.userId }).then(res => {
          message.success(res.message)
          queryList('', searchFormState)
        })
      }
    };

    // 列表查询
    const queryList = (cur, condition = {}) => {
      if (cur) {
        pagination.value.current = cur
      }
      let param = {
        page: pagination.value.current,
        size: pagination.value.pageSize,
        ...condition
      }
      myPost(api.queryPageUser, param).then((res) => {
        tabaleData.value = res.data.map((item) => {
          return {
            ...item
            // switch: item.userEnable === 1 ? true : false
          }
        })
        pagination.value.total = res.total
      })
        .catch((err) => {
          console.log(err);
        })
    }
    onMounted(() => {
      queryList(1)
      // queryAllRoles()
    })

    // 分页
    const pagination = ref({
      total: 50,
      current: 1,
      pageSize: 10,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
    });

    const paginationChange = (page, pageSize) => {
      pagination.value.page = page;
      pagination.value.pageSize = pageSize;
      queryList('')
    };
    const onShowSizeChange = (page, pageSize) => {
      pagination.value.page = page;
      pagination.value.pageSize = pageSize;
      queryList('')
    };

    const simpleStopUse = (record) => {
      myPost(api.simpleUseUrl, { id: record.userId, userEnable: record.userEnable === 1 ? 2 : 1 }).then(res => {
        message.success(res.message)
        queryList(1, searchFormState)
      })
    }

    return {
      searchFormState,
      // rolesOptions,
      customDialog,
      modalState,
      labelCol: {
        span: 7,
      },
      wrapperCol: {
        span: 13,
      },
      formStateRules,
      sumbitEditForm,
      search,
      clearFormState,
      formState,
      formRef,
      tabaleData,
      columns,
      paginationChange,
      onShowSizeChange,
      pagination,
      operate,
      simpleStopUse
      // batchOperate,
      // rowSelection,
      // batchData,
      // clearSelect
    };
  },
});
</script>

<style lang="scss" scoped>
.searchInputWidth {
  width: 220px;
}
.ml20 {
  margin-left: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.dropbox {
  width: 400px;
  height: 180px;
  line-height: 1.5;
}
</style>