<template>
  <div>
    <a-modal v-model:visible="visible" @ok="handleOk" @cancel="handleCancel" :centered="true" :width="width" class="customdialog"
      :title="title" :maskClosable="false" :footer="footer">
      <slot></slot>
      <!-- <div style="display: flex;justify-content: center;align-items: center;height:92px;border-top: 1px dashed #D9D9D9;margin-top: 30px;text-align: center;
      width: 100%;">
            <div class="confirmbtn" @click="btnClick('confirm')">确定</div>
            <div class="cannelbtn" @click="btnClick('cannel')">取消</div>
          </div> -->
    </a-modal>

  </div>
</template>
<script>
  import { defineComponent, ref } from 'vue';
  export default defineComponent({
    props: {
      title: {
        type: String,
        default: 'Modal'
      },
      width: {
        type: Number,
        default: 780
      },
      footer: {
        type: Object,
      }
    },
    emits: ['handleOk','handleCancel'],
    setup(props, { emit }) {
      const visible = ref(false);

      const showModal = () => {
        visible.value = true;

      };
      const closeModal = () => {
        visible.value = false;
      };
      const handleOk = e => {
        // visible.value = false;
        emit('handleOk', e)
      };

      const handleCancel = e => {

        // visible.value = false;
        emit('handleCancel', e)
      };
      return {
        visible,
        showModal,
        handleOk,
        handleCancel,
        closeModal
      };
    },
  });
</script>
<style scoped>
  /* .customdialog >>>.ant-modal-header{
    border-bottom: 1px dashed #D9D9D9!important;

  }

  :deep(.ant-modal-title){
    color: red!important;
  } */
  .customdialog {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    border-radius: 10px;
  }

  .footer {
    width: 780px;
    text-align: center;
    margin-top: 30px;
    height: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px dashed #D9D9D9;
  }

  .confirmbtn {

    width: 82px;
    line-height: 32px;
    background: #637CFE;
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
  }

  .cannelbtn {
    margin-left: 20px;
    width: 82px;
    line-height: 32px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    font-family: Noto Sans S Chinese;
    font-weight: 400;
    line-height: 24px;
    color: #6A6A6A;
  }
</style>