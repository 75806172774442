// 加密
export function encode64(input) {
  input += '';
  var keyStr =
      'ABCDEFGHIJKLMNOP' +
      'QRSTUVWXYZabcdef' +
      'ghijklmnopqrstuv' +
      'wxyz0123456789+/' +
      '=';
  var output = '';
  var chr1,
      chr2,
      chr3 = '';
  var enc1,
      enc2,
      enc3,
      enc4 = '';
  var i = 0;
  do {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);
      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;
      if (isNaN(chr2)) {
          enc3 = enc4 = 64;
      } else if (isNaN(chr3)) {
          enc4 = 64;
      }
      output =
          output +
          keyStr.charAt(enc1) +
          keyStr.charAt(enc2) +
          keyStr.charAt(enc3) +
          keyStr.charAt(enc4);
      chr1 = chr2 = chr3 = '';
      enc1 = enc2 = enc3 = enc4 = '';
  } while (i < input.length);
  var arr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdef'.split('');
  var a = _ => Math.floor(Math.random() * 31 + 0);
  for (let i = 0; i < 5; i++) {
      output += arr[a()];
  }
  var sp = '';
  for (let i = 0; i < 5; i++) {
      sp += arr[a()];
  }
  output = output.slice(0, 2) + sp + output.slice(2);
  return output;
}
